import React from 'react'
import { Link } from 'gatsby'
import ReactSVG from 'react-svg'

import Items from '@/components/Navigation/Items'

export default class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: '',
      content: '',
      navigation: [],
      isMenuOpen: false,
      isNavigationVisible: false,
    }
    this.activateMenu = this.activateMenu.bind(this)
    this.deactivateMenu = this.deactivateMenu.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.deactivateMenu)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.deactivateMenu)
  }

  activateMenu() {
    this.setState((prevState) => ({
      isMenuOpen: !prevState.isMenuOpen,
    }))
  }

  deactivateMenu() {
    this.setState({
      isMenuOpen: false,
    })
  }

  render() {
    let btnClass =
      'btn btn-nav' + (this.state.isMenuOpen ? ' btn-nav--active' : '')
    let navClass =
      'mobile-nav' + (this.state.isMenuOpen ? ' mobile-nav--active' : '')

    return (
      <header className='header py-4'>
        <div className='container-lg'>
          <div className='flex justify-between items-center header__inner'>
            <Link to='/' className='footer__brand'>
              <ReactSVG src='img/souldefender.svg' />
            </Link>
            <div className='hidden md:flex flex-wrap space-x-4'>
              <Items />
            </div>
            <div className='block md:hidden'>
              <div
                className={btnClass}
                aria-label='Activate mobile menu'
                role='button'
                onClick={this.activateMenu}
                tabIndex={-5}
                onKeyDown={this.activateMenu}>
                <div className='btn-nav__inner'>
                  <div className='btn-nav__element'></div>
                  <div className='btn-nav__element'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={navClass}>
          <div className='mobile-nav__nav'>
            <Items />
          </div>
        </div>
      </header>
    )
  }
}
