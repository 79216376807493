import React from 'react'
import { globalHistory as history } from '@reach/router'
import { Link } from 'gatsby'

const Items = () => {
  const { location } = history
  const path = location.pathname
  let linkClass =
    path === '/contact' ? 'nav-item text-black' : 'nav-item text-white'

  return (
    <>
      <a href='https://dionysus.events/' className={linkClass}>
        Home
      </a>
      <a href='https://dionysus.events/projects' className={linkClass}>
        Projects
      </a>
      <a href='/' className={linkClass}>
        Soul Defender
      </a>
      <a href='https://dionysus.events/people' className={linkClass}>
        People
      </a>
      <Link to='contact' className={linkClass}>
        Contact
      </Link>
    </>
  )
}

export default Items
