import React from 'react'
import ReactSVG from 'react-svg'

import Items from '@/components/Navigation/Items'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className='footer pt-3 md:py-10'>
        <div className='container-lg'>
          <div className='flex flex-col md:flex-row justify-between items-center'>
            <div className='footer__brand'>
              <ReactSVG src='img/souldefender.svg' />
            </div>
            <div className='flex flex-col mt-6 md:mt-0 md:flex-row text-center md:text-left flex-wrap space-x-4'>
              <Items />
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
